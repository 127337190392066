@import "../utilities/mixins";

$media-border-color: bulmaRgba($border, 0.5) !default;
$media-border-size: 1px !default;
$media-spacing: 1rem !default;
$media-spacing-large: 1.5rem !default;
$media-content-spacing: 0.75rem !default;
$media-level-1-spacing: 0.75rem !default;
$media-level-1-content-spacing: 0.5rem !default;
$media-level-2-spacing: 0.5rem !default;

.media {
  align-items: flex-start;
  display: flex;
  text-align: inherit;
  .content:not(:last-child) {
    margin-bottom: $media-content-spacing; }
  .media {
    border-top: $media-border-size solid $media-border-color;
    display: flex;
    padding-top: $media-level-1-spacing;
    .content:not(:last-child),
    .control:not(:last-child) {
      margin-bottom: $media-level-1-content-spacing; }
    .media {
      padding-top: $media-level-2-spacing;
      & + .media {
        margin-top: $media-level-2-spacing; } } }
  & + .media {
    border-top: $media-border-size solid $media-border-color;
    margin-top: $media-spacing;
    padding-top: $media-spacing; }
  // Sizes
  &.is-large {
    & + .media {
      margin-top: $media-spacing-large;
      padding-top: $media-spacing-large; } } }

.media-left,
.media-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0; }

.media-left {
  @include ltr-property("margin", $media-spacing); }

.media-right {
  @include ltr-property("margin", $media-spacing, false); }

.media-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: inherit; }

@include mobile {
  .media-content {
    overflow-x: auto; } }
