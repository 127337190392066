@import "../utilities/mixins";

$notification-background-color: $background !default;
$notification-code-background-color: $scheme-main !default;
$notification-radius: $radius !default;
$notification-padding: 1.25rem 2.5rem 1.25rem 1.5rem !default;
$notification-padding-ltr: 1.25rem 2.5rem 1.25rem 1.5rem !default;
$notification-padding-rtl: 1.25rem 1.5rem 1.25rem 2.5rem !default;

$notification-colors: $colors !default;

.notification {
  @extend %block;
  background-color: $notification-background-color;
  border-radius: $notification-radius;
  position: relative;
  @include ltr {
    padding: $notification-padding-ltr; }
  @include rtl {
    padding: $notification-padding-rtl; }
  a:not(.button):not(.dropdown-item) {
    color: currentColor;
    text-decoration: underline; }
  strong {
    color: currentColor; }
  code,
  pre {
    background: $notification-code-background-color; }
  pre code {
    background: transparent; }
  & > .delete {
    @include ltr-position(0.5rem);
    position: absolute;
    top: 0.5rem; }
  .title,
  .subtitle,
  .content {
    color: currentColor; }
  // Colors
  @each $name, $pair in $notification-colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
      // If light and dark colors are provided
      @if length($pair) >= 4 {
        $color-light: nth($pair, 3);
        $color-dark: nth($pair, 4);
        &.is-light {
          background-color: $color-light;
          color: $color-dark; } } } } }
