@import "../utilities/mixins";

$title-color: $text-strong !default;
$title-family: false !default;
$title-size: $size-3 !default;
$title-weight: $weight-semibold !default;
$title-line-height: 1.125 !default;
$title-strong-color: inherit !default;
$title-strong-weight: inherit !default;
$title-sub-size: 0.75em !default;
$title-sup-size: 0.75em !default;

$subtitle-color: $text !default;
$subtitle-family: false !default;
$subtitle-size: $size-5 !default;
$subtitle-weight: $weight-normal !default;
$subtitle-line-height: 1.25 !default;
$subtitle-strong-color: $text-strong !default;
$subtitle-strong-weight: $weight-semibold !default;
$subtitle-negative-margin: -1.25rem !default;

.title,
.subtitle {
  @extend %block;
  word-break: break-word;
  em,
  span {
    font-weight: inherit; }
  sub {
    font-size: $title-sub-size; }
  sup {
    font-size: $title-sup-size; }
  .tag {
    vertical-align: middle; } }

.title {
  color: $title-color;
  @if $title-family {
    font-family: $title-family; }
  font-size: $title-size;
  font-weight: $title-weight;
  line-height: $title-line-height;
  strong {
    color: $title-strong-color;
    font-weight: $title-strong-weight; }
  &:not(.is-spaced) + .subtitle {
    margin-top: $subtitle-negative-margin; }
  // Sizes
  @each $size in $sizes {
    $i: index($sizes, $size);
    &.is-#{$i} {
      font-size: $size; } } }

.subtitle {
  color: $subtitle-color;
  @if $subtitle-family {
    font-family: $subtitle-family; }
  font-size: $subtitle-size;
  font-weight: $subtitle-weight;
  line-height: $subtitle-line-height;
  strong {
    color: $subtitle-strong-color;
    font-weight: $subtitle-strong-weight; }
  &:not(.is-spaced) + .title {
    margin-top: $subtitle-negative-margin; }
  // Sizes
  @each $size in $sizes {
    $i: index($sizes, $size);
    &.is-#{$i} {
      font-size: $size; } } }
